'use strict';

var dojoConfig = {
    has: {
        "esri-featurelayer-webgl": 1
    }
};

angular.module('schoolbushubApp', ['LocalStorageModule', 'tmh.dynamicLocale', 'pascalprecht.translate',
    'ngResource', 'ngCookies', 'ngAria', 'ngCacheBuster', 'ngFileUpload', 'ngSanitize', 'ui.select', 'ui.slider',
    'seon.queryBuilder', // jhipster-needle-angularjs-add-module JHipster will add new module here
    'ui.bootstrap', 'ui.router',  'infinite-scroll', 'angular-loading-bar', 'ngMaterial', 'ngMessages', 'ngAnimate',
    'ui.mask', 'badge-counter','rzSlider', 'ui.grid', 'ui.grid.pagination', 'ui.grid.resizeColumns', 'ui.grid.moveColumns',
    'debounce'])

    .run(function ($rootScope, $mdMedia, $location, $window, $http, $state, $translate, $timeout, Language, Auth, Principal, ENV, VERSION) {
        $rootScope.$state = $state;

        $rootScope.$mdMedia = $mdMedia;
        // update the window title using params in the following
        // precendence
        // 1. titleKey parameter
        // 2. $state.$current.data.pageTitle (current state page title)
        // 3. 'global.title'
        var updateTitle = function(titleKey) {
            // if (!titleKey && $state.$current.data && $state.$current.data.pageTitle) {
            //     titleKey = $state.$current.data.pageTitle;
            // }
            // $translate(titleKey || 'global.title').then(function (title) {
            //     $window.document.title = title;
            // });
        };

        $rootScope.ENV = ENV;
        $rootScope.VERSION = VERSION;
        $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams) {
            $rootScope.toState = toState;
            $rootScope.toStateParams = toStateParams;

            if (Principal.isIdentityResolved()) {
                Auth.authorize();
            }


            // Update the language
            Language.getCurrent().then(function (language) {
                $translate.use(language);
                // console.log(language)
                moment.locale(language);
            });

        });

        $rootScope.$on('$stateChangeSuccess',  function(event, toState, toParams, fromState, fromParams) {
            var titleKey = 'global.title' ;
            toggleOverlay(false);

            // Remember previous state unless we've been redirected to login or we've just
            // reset the state memory after logout. If we're redirected to login, our
            // previousState is already set in the authExpiredInterceptor. If we're going
            // to login directly, we don't want to be sent to some previous state anyway
            if (toState.name != 'login' && $rootScope.previousStateName) {
              $rootScope.previousStateName = fromState.name;
              $rootScope.previousStateParams = fromParams;
            }

            // Set the page title key to the one configured in state or use default one
            if (toState.data.pageTitle) {
                titleKey = toState.data.pageTitle;
            }
            updateTitle(titleKey);

            if ($state.current.name == "home") {
                $rootScope.menuBgStyle = {'background-color': 'rgba(0,0,0,0.7)'}
                $rootScope.contentBgStyle = {'background-color': 'rgba(0,0,0,0.0)'}
            }
            else {
                $rootScope.menuBgStyle = {'background-color': 'black'}
                $rootScope.contentBgStyle = {'background-color': '#DFDFDF'}
            }
        });

        // if the current translation changes, update the window title
        $rootScope.$on('$translateChangeSuccess', function() {updateTitle(); });


        $rootScope.back = function() {
            // If previous state is 'activate' or do not exist go to 'home'
            if ($rootScope.previousStateName === 'activate' || $state.get($rootScope.previousStateName) === null) {
                $state.go('home');
            } else {
                $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
            }
        };


        //page size for admin templates to adjust automatically based on screen resolution
        function setAdminPageSize() {
            $rootScope.infiniteScrollPageSize = Math.ceil(($window.innerHeight - 240) / 37);
        }
        setAdminPageSize();
        angular.element($window).bind('resize', function () {
            setAdminPageSize();
        });


        //block ui overlay methods
        $rootScope.$on('hideSpinner', function(){ toggleOverlay(false); });

        $rootScope.$on('showSpinner', function(){
            toggleOverlay(true);
            $timeout(function() {
                if (angular.element('.spinner').hasClass('shown')) {
                    toggleOverlay(false);
                }
            }, 45000);
        });

        function toggleOverlay(show) {
            angular.element('.spinner').toggleClass('shown', show? true : false);
        }
    })
    .config(function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider, $translateProvider, tmhDynamicLocaleProvider,
                      httpRequestInterceptorCacheBusterProvider, AlertServiceProvider) {
        // uncomment below to make alerts look like toast
        //AlertServiceProvider.showAsToast(true);

        //old style hashbang url
        $locationProvider.hashPrefix('');

        //Cache everything except rest api requests
        httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*api.*/, /.*protected.*/, /.*i18n.*/], true);

        moment.locale(['en', 'es']);

        $urlRouterProvider.otherwise('/');
        $stateProvider.state('site', {
            'abstract': true,
            views: {
                'navbar@': {
                    templateUrl: 'scripts/components/navbar/navbar.html',
                    controller: 'NavbarController'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        });

        $httpProvider.interceptors.push('errorHandlerInterceptor');
        $httpProvider.interceptors.push('authExpiredInterceptor');
        $httpProvider.interceptors.push('authInterceptor');
        $httpProvider.interceptors.push('notificationInterceptor');
        // jhipster-needle-angularjs-add-interceptor JHipster will add new application interceptor here

        // Initialize angular-translate
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: 'i18n/{lang}/{part}.json'
        });

        $translateProvider.preferredLanguage('en');
        $translateProvider.useCookieStorage();
        $translateProvider.useSanitizeValueStrategy('escaped');
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

        tmhDynamicLocaleProvider.localeLocationPattern('bower_components/angular-i18n/angular-locale_{{locale}}.js');
        tmhDynamicLocaleProvider.useCookieStorage();
        tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');

    })
    // jhipster-needle-angularjs-add-config JHipster will add new application configuration here
    .config(['$urlMatcherFactoryProvider', function($urlMatcherFactory) {
        $urlMatcherFactory.type('boolean', {
            name : 'boolean',
            decode: function(val) { return val == true ? true : val == "true" ? true : false },
            encode: function(val) { return val ? 1 : 0; },
            equals: function(a, b) { return this.is(a) && a === b; },
            is: function(val) { return [true,false,0,1].indexOf(val) >= 0 },
            pattern: /bool|true|0|1/
        });
    }])
    .config(function($mdThemingProvider) {
      $mdThemingProvider.definePalette('sbhpalette', {
        '50': '000000',
        '100': '000000',
        '200': '000000',
        '300': '000000',
        '400': '000000',
        '500': '000000',
        '600': '000000',
        '700': '000000',
        '800': '000000',
        '900': '000000',
        'A100': '000000',
        'A200': '000000',
        'A400': '000000',
        'A700': '000000',
        'contrastDefaultColor': 'light',    // whether, by default, text (contrast)
                                            // on this palette should be dark or light
        'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
        '200'],
        'contrastLightColors': ['A700', 'A400']    // could also specify this if default was 'dark'
      });

      $mdThemingProvider.theme('altTheme').primaryPalette('sbhpalette');

      // $mdThemingProvider.alwaysWatchTheme(true);
  })

.filter('propsFilter', function() {
    return function(items, props) {
        var out = [];

        if (angular.isArray(items)) {
            var keys = Object.keys(props);

            items.forEach(function(item) {
                var itemMatches = false;

                for (var i = 0; i < keys.length; i++) {
                    var prop = keys[i];
                    var text = props[prop].toLowerCase();
                    if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        itemMatches = true;
                        break;
                    }
                }

                if (itemMatches) {
                    out.push(item);
                }
            });
        }
        else {
            out = items;
        }

        return out;
    };
});
