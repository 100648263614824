"use strict";

angular
    .module("schoolbushubApp")
    .controller(
        "OrganizationController",
        function (
            $scope,
            $state,
            $uibModal,
            Organization,
            ParseLinks,
            $filter,
            AlertService,
            uiGridConstants,
            debounce
        ) {
            const HTTP_STATUS = { OK: 200, UNAUTHORIZED: 401, NOT_FOUND: 404 };
            $scope.organizations = [];
            $scope.organizationsGridOptions = {
                data: [],
                useExternalSorting: true,
                useExternalFiltering: true,
                useExternalPagination: true,
                suppressMultiSort: true,
                enableHiding: false,
                cellTooltip: true,
                enableFiltering: true,
                enableColumnResizing: true,
                paginationPageSizes: [10, 20, 50, 100],
                paginationPageSize: 10,
                columnDefs: [
                    {
                        field: "id",
                        displayName: $filter("translate")("global.field.id"),
                        type: "number",
                        cellClass: "organization-grid-cell",
                        headerCellClass: "organization-grid-header",
                        width: 100,
                        minWidth: 80,
                        maxWidth: 150,
                        filterHeaderTemplate:
                            '<div class="filter-container">' +
                            "<input " +
                            'class="input-filter" ' +
                            'ng-model="grid.appScope.idFilterValue" ' +
                            'ng-model-options="{ debounce: 500 }" ' +
                            'ng-change="grid.appScope.filter(col, grid.appScope.idFilterValue)"/>' +
                            "<img " +
                            'ng-if="grid.appScope.idFilterValue" ' +
                            'src="assets/images/filter-off.svg" ' +
                            'class="clear-filter" height="16" width="16" ' +
                            "ng-click=\"grid.appScope.filter(col, ''); grid.appScope.idFilterValue=''\" " +
                            "title=\"{{'filters.clear' | translate}}\">" +
                            "</div>",
                        filter: {
                            term: "",
                            condition: uiGridConstants.filter.EXACT,
                            placeholder: "",
                            flags: { caseSensitive: false },
                            type: uiGridConstants.filter.INPUT,
                            disableCancelFilterButton: true,
                        },
                    },
                    {
                        field: "name",
                        displayName: $filter("translate")("schoolbushubApp.organization.name"),
                        cellTooltip: true,
                        headerTooltip: true,
                        cellClass: "organization-grid-cell",
                        headerCellClass: "organization-grid-header",
                        width: 150,
                        minWidth: 80,
                        maxWidth: 250,
                        filterHeaderTemplate:
                            '<div class="filter-container">' +
                            "<input " +
                            'class="input-filter" ' +
                            'ng-model="grid.appScope.nameFilterValue" ' +
                            'ng-model-options="{ debounce: 500 }" ' +
                            'ng-change="grid.appScope.filter(col, grid.appScope.nameFilterValue)"/>' +
                            "<img " +
                            'ng-if="grid.appScope.nameFilterValue" ' +
                            'src="assets/images/filter-off.svg" ' +
                            'class="clear-filter" height="16" width="16" ' +
                            "ng-click=\"grid.appScope.filter(col, ''); grid.appScope.nameFilterValue=''\" " +
                            "title=\"{{'filters.clear' | translate}}\">" +
                            "</div>",
                        filter: {
                            term: "",
                            condition: uiGridConstants.filter.CONTAINS,
                            placeholder: "",
                            flags: { caseSensitive: false },
                            type: uiGridConstants.filter.INPUT,
                            disableCancelFilterButton: true,
                        },
                    },
                    {
                        field: "timezone",
                        displayName: $filter("translate")("schoolbushubApp.organization.timezone"),
                        cellTooltip: true,
                        headerTooltip: true,
                        cellClass: "organization-grid-cell",
                        headerCellClass: "organization-grid-header",
                        width: 150,
                        minWidth: 80,
                        maxWidth: 250,
                        filterHeaderTemplate:
                            '<div class="filter-container">' +
                            "<input " +
                            'class="input-filter" ' +
                            'ng-model="grid.appScope.timezoneFilterValue" ' +
                            'ng-model-options="{ debounce: 500 }" ' +
                            'ng-change="grid.appScope.filter(col, grid.appScope.timezoneFilterValue)"/>' +
                            "<img " +
                            'ng-if="grid.appScope.timezoneFilterValue" ' +
                            'src="assets/images/filter-off.svg" ' +
                            'class="clear-filter" height="16" width="16" ' +
                            "ng-click=\"grid.appScope.filter(col, ''); grid.appScope.timezoneFilterValue=''\" " +
                            "title=\"{{'filters.clear' | translate}}\">" +
                            "</div>",
                        filter: {
                            term: "",
                            condition: uiGridConstants.filter.CONTAINS,
                            placeholder: "",
                            flags: { caseSensitive: false },
                            type: uiGridConstants.filter.INPUT,
                            disableCancelFilterButton: true,
                        },
                    },
                    {
                        field: "code",
                        displayName: $filter("translate")("schoolbushubApp.organization.organizationGuid"),
                        cellTooltip: true,
                        headerTooltip: true,
                        cellClass: "organization-grid-cell",
                        headerCellClass: "organization-grid-header",
                        width: 170,
                        minWidth: 80,
                        maxWidth: 250,
                        filterHeaderTemplate:
                            '<div class="filter-container">' +
                            "<input " +
                            'class="input-filter" ' +
                            'ng-model="grid.appScope.codeFilterValue" ' +
                            'ng-model-options="{ debounce: 500 }" ' +
                            'ng-change="grid.appScope.filter(col, grid.appScope.codeFilterValue)"/>' +
                            "<img " +
                            'ng-if="grid.appScope.codeFilterValue" ' +
                            'src="assets/images/filter-off.svg" ' +
                            'class="clear-filter" height="16" width="16" ' +
                            "ng-click=\"grid.appScope.filter(col, ''); grid.appScope.codeFilterValue=''\" " +
                            "title=\"{{'filters.clear' | translate}}\">" +
                            "</div>",
                        filter: {
                            term: "",
                            condition: uiGridConstants.filter.CONTAINS,
                            placeholder: "",
                            flags: { caseSensitive: false },
                            type: uiGridConstants.filter.INPUT,
                            disableCancelFilterButton: true,
                        },
                    },
                    {
                        field: "sourceApplication",
                        displayName: $filter("translate")("schoolbushubApp.organization.sourceApplication"),
                        enableSorting: false,
                        enableColumnMenu: false,
                        headerTooltip: true,
                        cellClass: "organization-grid-cell",
                        headerCellClass: "organization-grid-header no-sorting",
                        width: 160,
                        minWidth: 80,
                        maxWidth: 200,
                        placeholder: "All",
                        filter: {
                            term: null,
                            condition: uiGridConstants.filter.EXACT,
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                { value: "vmax", label: "Path" },
                                { value: "CMP", label: "Compass" },
                            ],
                            disableCancelFilterButton: true,
                        },
                    },
                    {
                        field: "eventSource",
                        displayName: $filter("translate")("schoolbushubApp.organization.eventSource"),
                        enableSorting: false,
                        enableColumnMenu: false,
                        headerTooltip: true,
                        enableFiltering: false,
                        cellClass: "organization-grid-cell",
                        headerCellClass: "organization-grid-header no-sorting",
                        width: 130,
                        minWidth: 80,
                        maxWidth: 150,
                    },
                    {
                        field: "saveAllVehicleAuditInfo",
                        cellTemplate:
                            '<div class="ui-grid-cell-contents">' +
                            '<span class="label label-danger" ng-show="!row.entity.saveAllVehicleAuditInfo">{{row.entity.saveAllVehicleAuditInfo}}</span>' +
                            '<span class="label label-success" ng-show="row.entity.saveAllVehicleAuditInfo">{{row.entity.saveAllVehicleAuditInfo}}</span>' +
                            "</div>",
                        displayName: $filter("translate")("schoolbushubApp.organization.saveAllVehicleAuditInfo"),
                        enableSorting: false,
                        enableColumnMenu: false,
                        headerTooltip: true,
                        enableFiltering: false,
                        cellClass: "organization-grid-cell",
                        headerCellClass: "organization-grid-header no-sorting",
                        width: 210,
                        minWidth: 80,
                        maxWidth: 220,
                    },
                    {
                        field: "sendRoutingNotifications",
                        cellTemplate:
                            '<div class="ui-grid-cell-contents">' +
                            '<span class="label label-danger" ng-show="!row.entity.sendRoutingNotifications">{{row.entity.sendRoutingNotifications}}</span>' +
                            '<span class="label label-success" ng-show="row.entity.sendRoutingNotifications">{{row.entity.sendRoutingNotifications}}</span>' +
                            "</div>",
                        displayName: $filter("translate")("schoolbushubApp.organization.sendRoutingNotifications"),
                        enableSorting: false,
                        enableColumnMenu: false,
                        headerTooltip: true,
                        enableFiltering: false,
                        cellClass: "organization-grid-cell",
                        headerCellClass: "organization-grid-header no-sorting",
                        minWidth: 80,
                    },
                    {
                        field: "id",
                        cellTemplate:
                            '<div class="btn-group flex-btn-group-container">' +
                            '<button type="submit" style="font-size: 9px;" ng-click="grid.appScope.viewOrganizationDetail(row.entity)" class="btn no-bg-color">' +
                            '<img src="assets/images/eye.svg" height="16" width="16" title="{{"entity.action.view" | translate}}">' +
                            "</button>" +
                            '<button type="submit" style="font-size: 9px;" ui-sref="organization.edit({id:row.entity.id})" class="btn no-bg-color">' +
                            '<img src="assets/images/pencil.svg" height="15" width="15" title="{{"entity.action.edit" | translate}}">' +
                            "</button>" +
                            '<button type="submit" style="font-size: 9px;" ui-sref="organization.delete({id:row.entity.id})" class="btn no-bg-color">' +
                            '<img src="assets/images/trash.svg" height="15" width="15" title="{{"entity.action.delete" | translate}}">' +
                            "</button>" +
                            '<button type="submit" style="font-size: 9px;" ng-click="grid.appScope.syncSchools(row.entity)" class="btn no-bg-color" ng-disabled="grid.appScope.disabledSyncSchools">' +
                            '<img src="assets/images/sync.svg" height="15" width="15" title="{{"schoolbushubApp.organization.syncSchools" | translate}}">' +
                            "</button>" +
                            "</div>",
                        displayName: $filter("translate")("schoolbushubApp.organization.actions"),
                        enableSorting: false,
                        enableColumnMenu: false,
                        headerTooltip: true,
                        enableFiltering: false,
                        minWidth: 170,
                        maxWidth: 170,
                        cellClass: "organization-grid-cell",
                        headerCellClass: "organization-grid-header no-sorting",
                        enableColumnResizing: false,
                    },
                ],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, $scope.sortChanged);
                    $scope.sortChanged($scope.gridApi.grid, [$scope.organizationsGridOptions.columnDefs[0]]);
                    $scope.gridApi.core.on.filterChanged($scope, debounce($scope.filterChanged, 1000));
                    $scope.gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                        $scope.per_page = pageSize;
                        $scope.loadPage(newPage - 1);
                    });
                },
            };
            $scope.predicate = "id";
            $scope.reverse = true;
            $scope.queryObject = {
                operator: "and",
                rules: [],
            };
            $scope.page = 0;
            $scope.per_page = $scope.organizationsGridOptions.paginationPageSize;
            $scope.loadAll = function () {
                var pageable = {
                    page: $scope.page,
                    size: $scope.per_page,
                    sort: [$scope.predicate + "," + ($scope.reverse ? "asc" : "desc"), "id"],
                };
                Organization.resource.filterQuery(
                    { pageable: pageable, filters: $scope.queryObject },
                    function (result, headers) {
                        $scope.links = ParseLinks.parse(headers("link"));
                        $scope.total = headers("x-total-count");
                        $scope.organizationsGridOptions.totalItems = headers("x-total-count");
                        $scope.organizations = [];
                        for (var i = 0; i < result.length; i++) {
                            result[i].sourceApplication = result[i].applicationCode === "CMP" ? "Compass" : "Path";
                            $scope.organizations.push($scope.dataMapping(result[i]));
                        }
                        $scope.organizationsGridOptions.data = $scope.organizations;
                    },
                    function (err) {
                        AlertService.error($filter("translate")("filters.resultMessages.resultsError"));
                    }
                );
            };

            $scope.dataMapping = function (data) {
                var organization = {
                    id: data.id,
                    name: data.name,
                    timezone: data.timezone,
                    code: data.code,
                    sourceApplication: data.sourceApplication,
                    applicationCode: data.applicationCode,
                    eventSource: data.eventSource,
                    saveAllVehicleAuditInfo: data.saveAllVehicleAuditInfo,
                    sendRoutingNotifications: data.sendRoutingNotifications,
                };
                return organization;
            };

            $scope.sortChanged = function (grid, sortColumns) {
                $scope.predicate = (sortColumns.length && sortColumns[0].field) || "id";
                $scope.reverse =
                    sortColumns.length && sortColumns[0].sort && sortColumns[0].sort.direction === "desc"
                        ? false
                        : true;
                $scope.reset();
            };

            $scope.filterChanged = function () {
                var grid = this.grid || this.gridApi.grid;
                $scope.queryObject.rules = [];
                for (var i = 0; i < grid.columns.length; i++) {
                    if (grid.columns[i].filter.term) {
                        $scope.queryObject.rules.push({
                            field:
                                grid.columns[i].field !== "sourceApplication"
                                    ? grid.columns[i].field
                                    : "applicationCode",
                            condition:
                                grid.columns[i].colDef.filter.condition === uiGridConstants.filter.EXACT ? "=" : "like",
                            data: grid.columns[i].filter.term,
                        });
                    }
                }
                $scope.reset();
            };

            $scope.filter = function (column, filter) {
                column.filter.term = filter;
                $scope.gridApi.grid.refresh();
            };

            $scope.reset = function () {
                $scope.page = 0;
                $scope.organizationsGridOptions.paginationCurrentPage = 1;
                $scope.per_page = $scope.organizationsGridOptions.paginationPageSize;
                $scope.organizations = [];
                $scope.loadAll();
            };
            $scope.loadPage = function (page) {
                if ($scope.links && page > $scope.links["last"]) {
                    return;
                }
                $scope.page = page;
                $scope.loadAll();
            };
            $scope.fields = Organization.resource.filterVars();
            $scope.loadAll();

            $scope.refresh = function () {
                $scope.reset();
                $scope.clear();
            };

            $scope.clear = function () {
                $scope.organization = {
                    name: null,
                    apiUser: null,
                    apiPassword: null,
                    timezone: null,
                    organizationGuid: null,
                    arrivalTimeWindow: null,
                    id: null,
                };
            };

            var syncSchoolsCallback = function (response, organization) {
                if (response.data == HTTP_STATUS.OK) {
                    AlertService.success(
                        $filter("translate")("schoolbushubApp.organization.synchronizedSchools", {
                            organization: organization.code,
                        })
                    );
                } else if (response.data == HTTP_STATUS.NOT_FOUND) {
                    AlertService.error(
                        $filter("translate")("schoolbushubApp.organization.organizationHasNoSchools", {
                            organization: organization.code,
                        })
                    );
                } else if (response.data == HTTP_STATUS.UNAUTHORIZED) {
                    AlertService.error(
                        $filter("translate")("schoolbushubApp.organization.organizationNotFound", {
                            organization: organization.code,
                        })
                    );
                } else {
                    AlertService.error(
                        $filter("translate")("schoolbushubApp.organization.errorSynchronizingSchools", {
                            organization: organization.code,
                        })
                    );
                }
                $scope.disabledSyncSchools = false;
            };

            $scope.syncSchools = function (organization) {
                $scope.disabledSyncSchools = true;
                Organization.synchronizeSchools(organization, syncSchoolsCallback);
            };

            $scope.clearNotifications = function (organization) {
                Organization.clearNotifications(organization, function (response, organization) {
                    if (response.status == "success") {
                        AlertService.success(
                            $filter("translate")("schoolbushubApp.organization.clearedNotifications", {
                                organization: organization.code,
                            })
                        );
                    } else {
                        AlertService.error(
                            $filter("translate")("schoolbushubApp.organization.errorClearNotifications", {
                                organization: organization.code,
                            })
                        );
                    }
                });
            };

            $scope.viewOrganizationDetail = function (org) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: "scripts/app/entities/organization/organization-detail.html",
                    controller: "OrganizationDetailController",
                    size: "lg",
                    resolve: {
                        entity: function () {
                            return org;
                        },
                    },
                });
            };
        }
    );
